// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;

  @include hover-focus {
    text-decoration: none;
  }

  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
    pointer-events: none;
    cursor: default;
  }
}

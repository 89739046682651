// 
// 
// flickity.scss
//
//

@import "../plugins/flickity";

[data-flickity]{
  &:not(.flickity-enabled){
    opacity: 0;
  }
}

.flickity-page-dots{
  bottom: -2.125rem;
  .dot{
    width: 6px;
    height: 6px;
    border-radius: 10rem;
    margin: 0 5px;
    background: $body-color;
    opacity: .2;
    transition: $transition-base;
    &.is-selected{
      transform: scale(1.33333333);
    }
  }
}

.flickity-prev-next-button{
  padding: 0;
  background: $primary;
  border: 2px solid $primary;
  width: $spacer*3;
  height: $spacer*3;
  transition: $transition-base;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after{
    background-image: str-replace(url("data:image/svg+xml;utf8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12 5L5 12L12 19M19 12H6H19Z' stroke='#{$white}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e"), "#", "%23");
    content: '';
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    background-size: cover;
  }
  &.previous{
    left: $spacer*1.5;
  }
  &.next{
    right: $spacer*1.5;
    &:after{
      transform: rotateZ(180deg);
    }
  }
  &:hover{
    box-shadow: $box-shadow-sm;
    background: darken($primary,10%);
    border-color: darken($primary,10%);
  }
}

[data-flickity]{
  &.buttons-attached{
    .flickity-prev-next-button{
      border-color: $white;
      &.previous{
        left: -$spacer*1.5;
      }
      &.next{
        right: -$spacer*1.5;
      }
    }
  }
}

.flickity-button-icon{
  display: none;
}

@include media-breakpoint-down(lg){
  .flickity-prev-next-button{
    display: none;
  }
}

[data-flickity]{
  &.controls-light{
    .flickity-page-dots{
      .dot{
        background: $white;
      }
    }
  }
}

.controls-inside{
  &:not(:hover){
    .flickity-prev-next-button{
      opacity: 0;
    }
  }
  .flickity-page-dots{
    position: absolute;
    bottom: $spacer;
  }
}

.controls-inside, .arrows-inside{
  .flickity-prev-next-button{
    &.previous{
      left: $spacer;
    }
    &.next{
      right: $spacer;
    }
  }
}

.controls-hover{
  &:not(:hover){
    .flickity-prev-next-button, .flickity-page-dots{
      opacity: 0;
    }
  }
}

.carousel-cell{
  width: 100%;
}

.highlight-selected{
  position: relative;
  z-index: 1;
  .carousel-cell{
    transition: opacity .2s ease, transform .2s ease;
    &:not(.is-selected){
      transform: scale(0.9);
      opacity: .25;
    }
  }
}

@include media-breakpoint-up(lg){
  .flex-lg-column{
    [data-flickity]{
      &.flex-fill{
        .flickity-viewport{
          flex: 1 1 auto;
          height: 100% !important;
        }
        .carousel-cell{
          height: 100%;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.navbar-brand-girl-color {
  color: $girlColor;
}

.navbar-brand-boy-color {
  color: $boyColor;
  position: relative;
  left: -5px;
}

.navbar-brand {
  font-weight: 900;
  font-size: 2em;
  text-shadow: 2px 2px rgba(90, 90, 90, 0.2);
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

label.required:after {
  content: ' *';
  color: #f00;
}

.eu-cookies {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  background-color: $primary-3;
  padding: 10px;
  text-align: center;
  z-index: 1000;
}

a.floating-signup-btn {
  position: fixed;
  left: -5px;
  top: 35%;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  z-index: 999;
  background-color: $primary;
  width: 50px;
  font-size: 15px;
  font-weight: bold;
  color: $white;
  padding: 10px 10px 10px 15px;
  border-bottom: 3px solid $primary-2;
  border-top: 3px solid $primary-2;
  border-right: 3px solid $primary-2;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  transition: 0.3s;
  box-shadow: 3px 3px 5px rgba(150,150,150,0.4);
}

a.floating-signup-btn:hover {
  color: $white;
  left:0;
  box-shadow: 3px 3px 5px rgba(150,150,150,0.8);
}



#mySidenav a {
  position: absolute; /* Position them relative to the browser window */
left: -80px; /* Position them outside of the screen */
transition: 0.3s; /* Add transition on hover */
padding: 15px; /* 15px padding */
width: 100px; /* Set a specific width */
text-decoration: none; /* Remove underline */
font-size: 20px; /* Increase font size */
color: white; /* White text color */
border-radius: 0 5px 5px 0; /* Rounded corners on the top right and bottom right side */
}

#mySidenav a:hover {
  left: 0; /* On mouse-over, make the elements appear as they should */
}

/* The about link: 20px from the top with a green background */
#about {
  top: 20px;
  background-color: #4CAF50;
}
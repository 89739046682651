/* nunito-regular - latin-ext_latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nunito-v16-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/nunito-v16-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/nunito-v16-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/nunito-v16-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/nunito-v16-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/nunito-v16-latin-ext_latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-italic - latin-ext_latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/nunito-v16-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/nunito-v16-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/nunito-v16-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/nunito-v16-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/nunito-v16-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/nunito-v16-latin-ext_latin-italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-600 - latin-ext_latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/nunito-v16-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/nunito-v16-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/nunito-v16-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/nunito-v16-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/nunito-v16-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/nunito-v16-latin-ext_latin-600.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-700 - latin-ext_latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/nunito-v16-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/nunito-v16-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/nunito-v16-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/nunito-v16-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/nunito-v16-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/nunito-v16-latin-ext_latin-700.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-900 - latin-ext_latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/nunito-v16-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/nunito-v16-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/nunito-v16-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/nunito-v16-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
  url('../fonts/nunito-v16-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/nunito-v16-latin-ext_latin-900.svg#Nunito') format('svg'); /* Legacy iOS */
}